/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

/* disable tinymce warning */
.tox-notifications-container {
    display: none !important;
}

/* hide arrows for input with type === 'number' inside a parent with class === 'hide-arrows'  */
.hide-arrows::-webkit-inner-spin-button input,
.hide-arrows::-webkit-outer-spin-button input {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.hide-arrows input {
    -moz-appearance: textfield !important;
}

.scrollbars::-webkit-scrollbar {
    display: none;
}

.scrollbars {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}

.aspect-square::before {
    float: left;
    padding-top: 100%;
    content: '';
}

.aspect-square::after {
    display: block;
    content: '';
    clear: both;
}

.leaflet-control-attribution > a > svg {
    display: none !important;
}

.aspect-image {
    aspect-ratio: 7 / 5;
}

@supports not (aspect-ratio: 7 / 5) {
    .aspect-image::before {
        float: left;
        padding-top: 71.43%;
        content: '';
    }

    .aspect-image::after {
        display: block;
        content: '';
        clear: both;
    }
}
